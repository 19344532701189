import { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import shortid from "shortid";

import BookingElementGrid from "../../components/Grid/BookingElementGrid/BookingElementGrid";
import { useSelector } from "../../hooks/useSelector";
import { addBookingElement } from "../../state/ducks/booking";
import { BookingElement } from "../../types/bookingTypes";

type Props = {};

/**
 * Container component for BookingElementGrid. Gets booking elements from state, and passes the down.
 */
const BookingElementGridContainer: FunctionComponent<Props> = () => {
  const dispatch = useDispatch();

  const bookingElements: BookingElement[] = useSelector(
    (state) => state.bookingReducer.booking.bookingElements
  );

  const handleAddBookingElement = (type: BookingElement["type"]) => {
    const newElement: BookingElement = {
      id: shortid.generate(),
      brand: null,
      description: "",
      type,
      soldHere: false,
    };

    // Dispatch element
    dispatch(addBookingElement(newElement));
  };

  return (
    <BookingElementGrid
      bookingElements={bookingElements}
      handleAddBookingElement={handleAddBookingElement}
    />
  );
};

export default BookingElementGridContainer;
