import { FunctionComponent } from "react";
import ErrorMessageCard from "../components/Cards/ErrorMessageCard";
import { useSelector } from "../hooks/useSelector";
import { StepperError, StepperErrorId } from "../types/errorTypes";

type Props = {
  exclude?: StepperErrorId;
};

const ErrorMessageContainer: FunctionComponent<Props> = ({ exclude }) => {
  const error = useSelector((state) => state.stepperReducer.stepError);

  return (
    <>
      {error && exclude !== error.id && (
        <ErrorMessageCard errorDescription={error.description} />
      )}
    </>
  );
};

export default ErrorMessageContainer;
